.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: #1e1e1e;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  max-width: 1000px;
  width: 80vh;
  text-align: center;
  position: relative;
  max-height: 90vh;
  /* overflow-y: auto; */
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.upgrade-button {
  background-color: #5d55e7;
  color: #fff;
  padding: 10px 20px;
  margin-bottom: 40px;
  border-radius: 4px;
  border: none;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.add-button {
  background-color: #ffffff05;
  color: #5d55e7;
  padding: 10px 30px;
  border-radius: 4px;
  border: none;
  margin-top: 20px;
  font-size: 12px;
  cursor: pointer;
}

.remove-button {
  background-color: #ffffff05;
  color: #5d55e7;
  padding: 5px 10px;
  margin-left: 10px;
  font-size: 12px;
  cursor: pointer;
}

.submit-button {
  background-color: #5d55e7;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.invite-container {
  margin-top: 20px;
  text-align: center;

}

.padding-bottom {
  padding-bottom: 40px;
}

.invite-container p {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.invite-container input[type="email"] {
  padding: 10px;
  border-radius: 4px;
  border: none;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.03);
  color: #fff;
}

.invite-container input[type="text"] {
  padding: 10px;
  border-radius: 4px;
  border: none;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.03);
  color: #fff;
}

.invite-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

h1 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

h2 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 10px;
}

canvas {
  width: 100%;
}

@media (max-width: 768px) {
  .modal-content {
    max-width: 80%;
  }
}