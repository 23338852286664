.main div[type="signin"] {
    overflow: hidden;
    position: fixed;
    /* Set the navbar to fixed position */
    top: 0;
    /* Position the navbar at the top of the page */
    width: 100%;
    /* Full width */
}

body {
    height: 100%;
    min-height: 100vh;
}

.fullscreen {
    height: 100%;
    min-height: 100vh;
}