.sale-banner {
  background-color: #ff5656;
  color: #fff;
  font-weight: bold;
  padding: 1rem;
  text-align: center;
  font-size: 1.5rem;
  margin: 1rem 0;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}