/* Global Formatting */
/* assistant-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Assistant';
  font-style: normal;
  src: url('../fonts/assistant-v19-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

.main,
.main input {
  font-size: 16px;
  line-height: 24px;
  color: #353740;
  font-family: "Assistant", sans-serif;
}

.main {
  padding-top: 50px;
  background-color: #101010;
  margin-top: 0px;
  display: flex;
  justify-content: center;
}

.main form {
  display: flex;
  flex-direction: column;
}

.main ::placeholder {
  color: #ffffff5d;
  opacity: 1;
}

.main span {
  color: white;
  text-align: left;
  padding-bottom: 8px;
}

.main div[type="headers"] {
  color: white;
  font-weight: bold;
  text-align: left;
  padding-bottom: 8px;
}

.main div[type="headers2"] {
  color: white;
  font-weight: bold;
  text-align: left;
}

.main div[type="container"] {
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
}

/* Column Blocks Formatting */
.main div[id="col1"] {
  background-color: rgba(255, 255, 255, 0.05);
  text-align: left;
}

.main div[id="waveholder"] {
  background-color: rgba(134, 134, 134, 0.711);
  text-align: left;
}

.main div[id="col2"] {
  background-color: rgba(255, 255, 255, 0.05);
  text-align: left;
}

.main div[id="col3"] {
  text-align: left;
}

.main div[id="col4"] {
  background-color: rgba(255, 255, 255, 0.02);
  text-align: left;
}



.main div[name="SuggestedLines"] {
  background-color: rgba(255, 255, 255, 0.05);
  text-align: left;
  padding-bottom: 8px;

}

.main div[name="RelatedWords"] {
  background-color: rgba(255, 255, 255, 0.02);
  text-align: left;
  padding-bottom: 8px;

}

.Sidebar {
  height: 100%;
  width: 20%;
  background-color: white;
  border-right: 1px solid #F0F4FB;
  padding-left: 15px;
  box-sizing: border-box;

}

/* Element Formatting */
.main input[type="submit"] {
  background-color: #5d55e7;
  padding: 12px 0;
  color: rgb(255, 255, 255);
  border-radius: 4px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

.main input[type="submit"][name="disabled"] {
  background-color: hsl(0, 0%, 22%);
  padding: 12px 0;
  color: rgb(255, 255, 255);
  border-radius: 4px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

.main input[type="submit"][name="generate"] {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.main input[type="submit"]:hover {
  background-color: hsl(245, 58%, 53%);
}

.main input[type="submit"]:focus {
  background-color: hsl(245, 58%, 44%);
}

.main select[name="languages"] {
  background-color: hsl(245, 58%, 53%);
}

.main button[type="submit"] {
  background-color: #7c3aed;
  padding: 12px 0;
  color: rgb(255, 255, 255);
  border-radius: 4px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

.main button[type="submit"][name="generate"] {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.main button[type="submit"]:hover {
  background-color: #6d28d9;
}

.main input[type="text"] {
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
  text-align: left;
  font-size: 14px;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 24px;
  /* border-color: rgba(255, 255, 255, 0.05); */
}

.main input[type="number"] {
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
  text-align: left;
  font-size: 14px;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 24px;
  /* border-color: rgba(255, 255, 255, 0.05); */
}

.main select[id="timeSignature"] {
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
  text-align: left;
  font-size: 14px;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 24px;
}

.main input[type="text"][id="lastLine"] {
  background-color: rgba(255, 255, 255, 0.0);
  color: white;
  text-align: left;
  font-size: 14px;
  padding-top: 20px;
  padding-left: 10px;
  padding-bottom: 0px;
  border-radius: 4px;
  margin-bottom: 24px;
}

.main input[type="text"][id="relWord"] {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.main input[type="range"] {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  text-align: left;
  margin-bottom: 30px;
}

.main textarea[type="text"] {
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
  border-radius: 4px;
  text-align: left;
  padding: 12px 16px;
  font-size: 14px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

}

.main textarea[type="text"][name="fixed"] {
  background-color: rgba(255, 255, 255, 0.00);
  border-top-left-radius: 0px;
  font-size: 12px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  resize: none;
}


.main select {
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
  text-align: left;
  font-size: 12px;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 12px 10px;
  margin-bottom: 24px;
  font-weight: 600;
}

/* Table formatting */
.main div[id="table-container"] {
  max-height: 300px;
  overflow-y: scroll;
  display: block;
  border-radius: 4px;
  padding: 0px;
}

.main table {
  border-collapse: collapse;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.0);
  border-radius: 1px;
}

.main th,
td {
  padding: 8px;
}

.main thead {
  background-color: rgba(255, 255, 255, 0.0);
  text-align: left;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.50);
  border: none;
  border-radius: 4px;
}

.main tbody {
  background-color: rgba(255, 255, 255, 0.01);
  font-size: 12px;
  color: #ffffff;
}

.button {
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
}

.activeButton {
  color: white;
  background: rgb(109, 40, 217);
}

.inactiveButton {
  color: white;
  background: rgba(255, 255, 255, 0.05);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
}

/* Landing Page Formatting */
h1[id="q"] {
  color: hsl(243, 75%, 75%);
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
}

h2[id="a"] {
  color: white;
  text-align: left;
  font-size: 14px;
}

div[id="question-block"] {
  margin-top: 48px;
  margin-bottom: 48px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 12px;
  border-color: gold;
}

hr {
  color: rgba(255, 255, 255, 0.121);
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  background: #eee;
  /* Placeholder background color */
}


.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}